import { createStore } from 'vuex'

export const API_URL = process.env.VUE_APP_API_URL;

interface DatasetEntry {
    id: number,
    name: string,
    description: string,
    resolution: number,
    param: Array<string>
}
interface TypeEntry {
    name: string,
    description: string,
    meta: 'name'
}
interface ParamAtype {
    parameter: string,
    atype: string,
    scale_min: number,
    scale_max: number
}
export const store = createStore({
    // strict: true,
    state: {
        m_update: 0,
        m_selected_parameter_class: 'Wind',
        m_selected_parameter: '',
        m_selected_dataset: '',
        m_selected_aggregate_type: '',
        m_selected_period: '0',
        m_current_coord: [-3, 47],
        m_parameter_classes: [
            'Wave',
            'Wind',
        ],
        m_stats: {},
        m_parameters: {
        } as Record<string, TypeEntry>,

        m_datasets: {
        } as Record<string, DatasetEntry>,

        m_aggregate_types: {
        } as Record<string, string>,

        m_param_aggregate_types: [
        ] as Array<ParamAtype>,

        m_periods: {
            '0': 'All Year',
            '1': 'Jan',
            '2': 'Feb',
            '3': 'Mar',
            '4': 'Apr',
            '5': 'May',
            '6': 'Jun',
            '7': 'Jul',
            '8': 'Aug',
            '9': 'Sep',
            '10': 'Oct',
            '11': 'Nov',
            '12': 'Dec',

        } as Record<string, string>,
    },
    actions: {
        async FetchDatasets(context) {
            return fetch(API_URL + "/api/dataset/").then((response) => {
                if (response.ok) {
                    response.json().then((o) => {
                        const dsets: Array<DatasetEntry> = o['results'];
                        context.state.m_datasets = {};
                        dsets.forEach(o => { context.state.m_datasets[o['id']] = o });
                        context.state.m_selected_dataset = dsets[0].id.toFixed(0);
                        context.state.m_update += 1;
                    })
                }
            })
        },
        async FetchAggregateTypes(context) {
            return fetch(API_URL + "/api/aggregate_type/").then((response) => {
                if (response.ok) {
                    response.json().then((o) => {
                        const dsets: Array<TypeEntry> = o['results'];

                        context.state.m_aggregate_types = {};
                        dsets.forEach(o => { context.state.m_aggregate_types[o['name']] = o["description"] });
                        context.state.m_selected_aggregate_type = 'median';
                        context.state.m_update += 1;
                    })
                }
            })
        },
        async FetchParameters(context) {
            return fetch(API_URL + "/api/parameter/").then((response) => {
                if (response.ok) {
                    response.json().then((o) => {
                        const dsets: Array<TypeEntry> = o['results'];
                        context.state.m_parameters = {};
                        dsets.forEach(o => { context.state.m_parameters[o['name']] = o });
                        context.state.m_selected_parameter = 'ws100';
                        context.state.m_update += 1;
                    })
                }
            })
        },
        async FetchParametersAType(context) {
            return fetch(API_URL + "/api/parameter_aggregate_type/").then((response) => {
                if (response.ok) {
                    response.json().then((o) => {
                        const p: Array<ParamAtype> = o['results'];
                        context.state.m_param_aggregate_types = [];
                        p.forEach(o => {
                            context.state.m_param_aggregate_types.push(o)
                        });
                        context.state.m_selected_parameter = 'ws100';
                        context.state.m_update += 1;
                    })
                }
            })
        },
        async FetchStatistics(context) {
            const coord = context.state.m_current_coord;
            return fetch(API_URL +
                "/api/statistics/?parameter="
                + context.state.m_selected_parameter
                + "&dataset="
                + context.state.m_selected_dataset
                + "&lonlat="
                + coord[0] + "," + coord[1]).then((response) => {
                    if (response.ok) {
                        response.json().then((o) => {
                            context.state.m_stats = o;
                        })
                    }
                })
        }
    },
    mutations: {

        SetCurrentCoord(state, coord: [number, number]) {
            state.m_current_coord = coord;
        },
        SelectParameterClass(state, name: string) {
            state.m_selected_parameter_class = name;
            if (state.m_selected_parameter) {
                if (state.m_parameters[state.m_selected_parameter].meta != name) {
                    for (const o of Object.keys(state.m_parameters).sort(
                        (a, b) => (state.m_parameters[a].description.localeCompare(state.m_parameters[b].description)))) {
                        if (state.m_parameters[o].meta == name) {
                            state.m_selected_parameter = o;
                            break;
                        }
                    }
                }
                let par = false
                state.m_datasets[state.m_selected_dataset].param.forEach(p => {
                    par ||= p == state.m_selected_parameter
                })
                if (!par) {
                    const d = Object.values(state.m_datasets).filter(
                        e => {
                            let meta = false;
                            e.param.forEach(o => {
                                return (meta ||= (state.m_parameters[o].name == state.m_selected_parameter))
                            });
                            return meta;
                        }
                    ).map(o => o.id);
                    state.m_selected_dataset = d[0].toString();
                }

                state.m_selected_aggregate_type = state.m_param_aggregate_types.filter(
                    p => p.parameter == state.m_selected_parameter
                )[0].atype;
                state.m_update += 1;
            }
        },
        SelectParameter(state, name: string) {
            for (const k in state.m_parameters) {
                if (state.m_parameters[k].description === name) {
                    state.m_selected_parameter = k;
                    state.m_update += 1;
                    state.m_selected_aggregate_type = state.m_param_aggregate_types.filter(p => p.parameter == k)[0].atype;
                    break;
                }
            }
        },
        SelectDataset(state, name: string) {
            for (const k in state.m_datasets) {
                if (state.m_datasets[k].name === name) {
                    state.m_selected_dataset = k;
                    state.m_update += 1;
                    break;
                }
            }
        },
        SelectAggregateType(state, name: string) {
            for (const k in state.m_aggregate_types) {
                if (state.m_aggregate_types[k] === name) {
                    state.m_selected_aggregate_type = k;
                    state.m_update += 1;
                    break;
                }
            }
        },
        SelectPeriod(state, name: string) {
            for (const k in state.m_periods) {
                if (state.m_periods[k] === name) {
                    state.m_selected_period = k;
                    state.m_update += 1;
                    break;
                }
            }
        },
    },
    getters: {
        IsInit: (state) => {
            return state.m_selected_dataset != '' &&
                state.m_selected_aggregate_type != '' &&
                state.m_selected_parameter != ''
        },
        Updated: (state) => {
            return state.m_update;
        },
        ParameterClasses: (state) => {
            return state.m_parameter_classes
        },
        Parameters: (state) => {
            return Object.values(state.m_parameters).
                filter(o => o.meta == state.m_selected_parameter_class).
                map(o => o.description)
        },
        SelectedParameterClass: (state) => {
            return state.m_selected_parameter_class;
        },
        SelectedParameterName: (state) => {
            return state.m_parameters[state.m_selected_parameter]?.description;
        },
        SelectedParameter: (state) => {
            return state.m_selected_parameter;
        },
        Datasets: (state) => {
            return Object.values(state.m_datasets).filter(
                e => {
                    let meta = false;
                    e.param.forEach(o => {
                        return (meta ||= (state.m_parameters[o].name == state.m_selected_parameter))
                    });
                    return meta;
                }
            ).map(o => o.name);
        },
        SelectedDataset: (state) => {
            return state.m_selected_dataset;
        },
        SelectedDatasetName: (state) => {
            return state.m_datasets[state.m_selected_dataset]?.name;
        },
        AggregateTypes: (state) => {
            const o = state.m_param_aggregate_types.filter(a => {
                return a.parameter == state.m_selected_parameter
            })
            return o.map(o => state.m_aggregate_types[o.atype])
        },
        SelectedScale: (state) => {
            const o = state.m_param_aggregate_types.find(a => {
                return a.parameter == state.m_selected_parameter && a.atype == state.m_selected_aggregate_type
            })
            return [o?.scale_min, o?.scale_max]
        },
        SelectedAggregateType: (state) => {
            return state.m_selected_aggregate_type;
        },
        SelectedAggregateTypeName: (state) => {
            return state.m_aggregate_types[state.m_selected_aggregate_type];
        },
        Periods: (state) => {
            return Object.values(state.m_periods);
        },
        SelectedPeriod: (state) => {
            return state.m_selected_period;
        },
        SelectedPeriodName: (state) => {
            return state.m_periods[state.m_selected_period];
        },
        DetailedStats: (state) => {
            return state.m_stats
        },
        CurrentCoord: (state) => {
            return state.m_current_coord;
        }

    }

});
