
import { Options, Vue } from "vue-class-component";
import MapView from "./components/map.vue";
import Selector from "./components/selector.vue";
import Stats from "./components/stats.vue";
import { store } from "./store/index";

@Options({
    components: {
        MapView,
        Selector,
        Stats,
    },
})
export default class App extends Vue {
    created() {
        let req: Promise<any>[] = [];
        req.push(store.dispatch("FetchParameters"));
        req.push(store.dispatch("FetchDatasets"));
        req.push(store.dispatch("FetchAggregateTypes"));
        req.push(store.dispatch("FetchParametersAType"));
        Promise.all(req).then(() =>
            store.dispatch("FetchStatistics")
        );
    }
}
