
import { Options, Vue } from "vue-class-component";
import { store, API_URL } from "../store/index";

@Options({
    components: {},
})
export default class Stats extends Vue {
    private m_stats = {};
    private m_url = "";

    fmt(s: any) {
        if (s === "null") return "-";
        return (s as number).toFixed(2);
    }
    get Periods() {
        return store.getters.Periods;
    }
    get LonLat() {
        let cc = store.getters.DetailedStats["coordinates lon, lat"];
        if (cc) return cc;
        return [];
    }
    get Stats() {
        return store.getters.DetailedStats['statistics'];
    }
}
