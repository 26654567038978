
import { Options, Vue } from "vue-class-component";
import Map from "ol/Map";
import WebGlMap from "ol/Map";

import View from "ol/View";
import { Tile } from "ol/layer";
import {
    fromLonLat,
    toLonLat,
    transformExtent,
    get as getProjection,
} from "ol/proj";
import { containsExtent } from "ol/extent";
import { OSM, Vector, XYZ, Raster } from "ol/source";
import VectorImageLayer from "ol/layer/Vector";
import { Image as ImageLayer } from "ol/layer";
import GeoJSON from "ol/format/GeoJSON";
import { Style, Text, Stroke, Fill, RegularShape } from "ol/style";
import "ol/ol.css";
import Feature from "ol/Feature";
import { tile } from "ol/loadingstrategy";
import TileGrid from "ol/tilegrid/TileGrid";
import { store, API_URL } from "../store/index";
import { Watch } from "vue-property-decorator";
import Graticule from "ol/layer/Graticule";
import Control from "ol/control/Control";
import { Geometry, Polygon } from "ol/geom";
import Point from "ol/geom/Point";
import Colormap from "colormap";
import RasterSource from "ol/source/Raster";
import { Coordinate } from "ol/coordinate";

@Options({
    components: {},
})
export default class MapView extends Vue {
    map = new Map({});
    source = new Vector();
    layer = new VectorImageLayer();
    status = new Control({});
    //@ts-ignore

    tile_grid: TileGrid;

    status_text = "";
    number_of_features = 0;
    number_of_visible_features = 0;
    tiles_count = 0;
    cmap = Colormap({
        colormap: "cubehelix",
        nshades: 100,
        format: "rgba",
        alpha: 0.7,
    }).reverse();

    update_count = 0;

    // range = [] as Array<number>;
    mounted() {
        this.status.setTarget(document.getElementById("status") as HTMLElement);
        // this.status.setProperties;

        const resolutions = new Array(22);
        for (let i = 0, ii = resolutions.length; i < ii; ++i) {
            resolutions[i] = (40075016.686 / Math.pow(2, 8 + i)) * 2;
        }
        this.tile_grid = new TileGrid({
            resolutions: resolutions,
            tileSize: [512, 512],
            extent: getProjection("EPSG:3857")?.getExtent(),
        });
        this.source = new Vector({
            loader: this.loader,
            strategy: tile(this.tile_grid),
        });
        // this.source.on(["featuresloadend"], this.tilesLoaded);
        this.layer = new VectorImageLayer({
            // source: this.source,
            // @ts-ignore
            style: this.styleArrow,
            minZoom: 5,
            //@ts-ignore
        });
        let raster = new RasterSource({
            sources: [this.layer, new OSM()],
            //@ts-ignore
            operation: (pixels, data) => {
                let pix_v = pixels[0] as Array<number>;
                let pix_osm = pixels[1] as Array<number>;

                if (
                    pix_osm[0] == 170 &&
                    pix_osm[1] == 211 &&
                    pix_osm[2] == 223 &&
                    !(
                        pix_v[0] == 0 &&
                        pix_v[1] == 0 &&
                        pix_v[2] == 0 &&
                        pix_v[3] == 0
                    )
                ) {
                    return pix_v;
                }
                const v =
                    0.299 * pix_osm[0] +
                    0.587 * pix_osm[1] +
                    0.114 * pix_osm[2];
                pix_osm[0] = v;
                pix_osm[1] = v;
                pix_osm[2] = v;
                return pix_osm;
            },
        });
        this.map = new WebGlMap({
            target: "map",
            layers: [
                //     new Tile({
                //         source: new OSM(),
                //     }),
                // this.layer,

                new ImageLayer({
                    source: raster,
                }),
                //@ts-ignore
                // new Graticule({
                //     // the style to use for the lines, optional.
                //     strokeStyle: new Stroke({
                //         color: "#666",
                //         width: 1,
                //         lineDash: [0.5, 10],
                //     }),
                //     showLabels: false,
                //     wrapX: false,
                // }),
            ],
            view: new View({
                center: fromLonLat([0, 47]),
                zoom: 6,
                constrainResolution: true,
            }),
            maxTilesLoading: 64,
        });
        // this.map.addControl(this.status);
        this.map.getView().on("change:resolution", this.RefreshMap);
        this.map.on("rendercomplete", this.tilesLoaded);

        this.map.on("click", (evt) => {
            this.layer.getFeatures(evt.pixel).then((f) => {
                f.forEach((f) => {
                    const e = f.getGeometry()?.getExtent();
                    if (e) {
                        store.commit("SetCurrentCoord", toLonLat([e[0], e[1]], "EPSG:3857"));
                        store.dispatch(
                            "FetchStatistics"
                        );
                    }
                });
            });
        });
    }
    get Update() {
        return store.getters.Updated;
    }

    @Watch("Update")
    OnUpdate(c: number) {
        this.RefreshMap();
        if (store.getters.IsInit) {
            this.layer.setSource(this.source);
        }
        if (
            store.getters.SelectedParameterName.toLowerCase().includes(
                "direction"
            )
        ) {
            //@ts-ignore
            this.layer.setStyle(this.styleArrow);
        } else {
            //@ts-ignore
            this.layer.setStyle(this.styleText);
        }
    }
    RefreshMap() {
        this.update_count += 1;
        this.layer?.getSource()?.clear();
        this.layer?.getSource()?.refresh();
        this.number_of_features = 0;
        this.number_of_visible_features = 0;
        this.tiles_count = 0;
        this.status_text = " Loading data...";
    }
    private tilesLoaded() {
        // this.tiles_count += 1;
        // console.log(this.tiles_count, this.tileCount());
        if (
            this.number_of_features > 0 &&
            this.number_of_visible_features > 0
            // this.tiles_count == this.tileCount()
        ) {
            this.status_text = "";
        } else {
            this.status_text =
                "\u26A0 No data for selected configuration in the current view";
        }
    }
    private tileCount() {
        let size = this.map.getSize();
        let view = this.map.getView();
        let extent = view.calculateExtent(size);
        let zoom = view.getZoom() as number;
        let count = 0;
        this.tile_grid.forEachTileCoord(extent, zoom, function () {
            count += 1;
        });
        return count;
    }
    private loader(
        extent: Array<number>,
        resolution: number,
        projection: any,
        success: undefined | ((f: Array<Feature<Geometry>>) => void)
    ) {
        let ex2 = transformExtent(extent, "EPSG:3857", "EPSG:4326");
        let req = new XMLHttpRequest();

        const zoom = Math.round(
            Math.log((40075016.686 / resolution) * 2) / Math.log(2) - 9
        );
        // resolutions[i] = (40075016.686 / Math.pow(2, 8 + i)) * 2;
        // r/2 = 4 / 2**(8+zoom)
        // 2**(8+Z) = 4 / r * 2
        // (8 + z) log(2) = log(4/r*2)
        // z = log(4/r*2) / log(2) - 8
        req.open(
            "GET",
            API_URL +
                "/api/aggregate/?bbox=" +
                ex2[0].toFixed(2) +
                "," +
                ex2[3].toFixed(2) +
                "," +
                ex2[2].toFixed(2) +
                "," +
                ex2[1].toFixed(2) +
                "&parameter=" +
                store.getters.SelectedParameter +
                "&period=" +
                store.getters.SelectedPeriod +
                "&type=" +
                store.getters.SelectedAggregateType +
                "&limit=10000&dataset=" +
                store.getters.SelectedDataset +
                "&zoom=" +
                zoom,
            true
        );
        req.setRequestHeader("Cache-Control", "max-age=86400");

        const i = this.update_count;
        req.onload = () => this.updateLayer(req.response, success, i);
        req.send();
    }
    private updateLayer(
        jsonResponse: string,
        success: undefined | ((f: Array<Feature<Geometry>>) => void),
        counter: number
    ) {
        if (counter != this.update_count) {
            // discard slow querry for previous zoom
            return;
        }
        let features = JSON.parse(jsonResponse);
        let g = new GeoJSON({ featureProjection: "EPSG:3857" });
        try {
            let map_extent = this.map
                .getView()
                .calculateExtent(this.map.getSize());
            let f = g.readFeatures(features["results"]);
            this.number_of_features += f.length;
            this.number_of_visible_features += f.filter((_f) =>
                containsExtent(
                    map_extent,
                    (_f.getGeometry() as Geometry).getExtent()
                )
            ).length;
            this.source.addFeatures(f);
            if (success) success(f);
            // this.layer.setSource(this.source);
        } catch (error) {
            console.log(error);
            console.log(jsonResponse);
        }
    }
    private styleArrow(feature: Feature) {
        let r = ((180 + Number(feature.get("value"))) * Math.PI) / 180;
        return [
            new Style({
                image: new RegularShape({
                    fill: new Fill({
                        color: "#333",
                    }),
                    points: 3,
                    radius: 4,
                    rotation: r,
                }),
            }),
            new Style({
                image: new RegularShape({
                    stroke: new Stroke({
                        color: "#333",
                        width: 0.5,
                    }),
                    points: 2,
                    radius: 7,
                    displacement: [0, -6],
                    radius2: 0,
                    rotation: r,
                }),
            }),
        ];
    }
    private color(value: number) {
        let [x0, x1] = store.getters.SelectedScale;
        return Math.round(((value - x0) / (x1 - x0)) * 100);
    }
    private styleText(feature: Feature) {
        // let v = Number(feature.get("value"));
        // let color = "";
        // if (v < this.range[1]) {
        //     color= "#3ea832"
        // } else if (v > this.range[2]) {
        //     color="#a88b32";
        // } else {
        //     color = "#a8323c";
        // }
        return [
            new Style({
                text: new Text({
                    font: "10px Arial",
                    text: String(feature.get("value").toFixed(1)),
                    // stroke: new Stroke({
                    //     color: "#fff",
                    //     width: 0,
                    // }),
                    // offsetY: -10,
                    fill: new Fill({
                        color: "#333", //this.cmap[100-Math.round(feature.get("value")/20*100)],
                    }),
                }),
            }),
            new Style({
                fill: new Fill({
                    color: this.cmap[this.color(feature.get("value"))],
                }),
                stroke: new Stroke({
                    color: "#fff",
                    width: 0.5,
                }),
                geometry: (feature) => {
                    const res = feature.get("resolution");
                    const z0 = Math.floor(Math.log(10 / res) / Math.log(2)) + 3;
                    //@ts-ignore
                    const rr = Math.max(
                        res,
                        res *
                            Math.pow(
                                2,
                                z0 -
                                    Math.round(
                                        this.map.getView().getZoom() as number
                                    )
                            )
                    );
                    const pt = feature.getGeometry() as Point;
                    let [x, y] = pt.getCoordinates();
                    [x, y] = toLonLat([x, y], "EPSG:3857");
                    // console.log(res, rr, x, y, z0, (
                    //                     this.map.getView().getZoom() as number
                    //                 ))

                    return new Polygon([
                        [
                            fromLonLat([x - rr / 2, y - rr / 2]),
                            fromLonLat([x - rr / 2, y + rr / 2]),
                            fromLonLat([x + rr / 2, y + rr / 2]),
                            fromLonLat([x + rr / 2, y - rr / 2]),
                            fromLonLat([x - rr / 2, y - rr / 2]),
                        ],
                    ]);
                },
            }),
        ];
    }
}
