
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class DropDown extends Vue {
    @Prop() options!: Array<string>;
    @Prop() value!: () => string;
    @Prop() cback!: (arg0: string) => void;

    m_show = false;

    Show(b?: boolean) {
        if (b === undefined) {
            this.m_show = !this.m_show;
        } else {
            this.m_show = b;
        }
    }
    get Selected() {
        return this.value();
    }
    get Available() {
        return this.options.filter((s) => s != this.value());
    }
    get MaxWidth() {
        return (Math.max(...this.options.map((o) => o.length)) + 6) * 0.75;
    }
    Toggle(option: string) {
        this.cback(option);
        this.$el.setAttribute("display", "none");
    }
}
