
import { Options, Vue } from "vue-class-component";
import DropDown from "./dropdown.vue";
import { store } from "../store/index";

@Options({
    components: {
        DropDown,
    },
})
export default class Selector extends Vue {
    SetParameterClass(name: string) {
        store.commit("SelectParameterClass", name);
        store.dispatch('FetchStatistics');
    }
    SetParameter(name: string) {
        store.commit("SelectParameter", name);
        store.dispatch('FetchStatistics');
    }
    SetDataset(name: string) {
        store.commit("SelectDataset", name);
        store.dispatch('FetchStatistics');
    }
    SetAggregate(name: string) {
        store.commit("SelectAggregateType", name);
    }
    SetPeriod(name: string) {
        store.commit("SelectPeriod", name);
    }
    get ParameterClasses() {
        return store.getters.ParameterClasses;
    }
    get SelectedParameterClass() {
        return () =>  store.getters.SelectedParameterClass;
    }
    get Parameters() {
        return store.getters.Parameters;
    }
    get SelectedParameter() {
        return () => store.getters.SelectedParameterName;
    }
    get Datasets() {
        return store.getters.Datasets;
    }
    get SelectedDataset() {
        return () => store.getters.SelectedDatasetName;
    }
    get Aggregates() {
        return store.getters.AggregateTypes;
    }
    get SelectedAggregate() {
        return () =>  store.getters.SelectedAggregateTypeName;
    }
    get Periods() {
        return store.getters.Periods;
    }
    get SelectedPeriod() {
        return () =>  store.getters.SelectedPeriodName;
    }
}
