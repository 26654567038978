import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1886d18d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropDown = _resolveComponent("DropDown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DropDown, {
      options: _ctx.ParameterClasses,
      value: _ctx.SelectedParameterClass,
      cback: _ctx.SetParameterClass
    }, null, 8, ["options", "value", "cback"]),
    _createVNode(_component_DropDown, {
      options: _ctx.Parameters,
      value: _ctx.SelectedParameter,
      cback: _ctx.SetParameter
    }, null, 8, ["options", "value", "cback"]),
    _createVNode(_component_DropDown, {
      options: _ctx.Datasets,
      value: _ctx.SelectedDataset,
      cback: _ctx.SetDataset
    }, null, 8, ["options", "value", "cback"]),
    _createVNode(_component_DropDown, {
      options: _ctx.Aggregates,
      value: _ctx.SelectedAggregate,
      cback: _ctx.SetAggregate
    }, null, 8, ["options", "value", "cback"]),
    _createVNode(_component_DropDown, {
      options: _ctx.Periods,
      value: _ctx.SelectedPeriod,
      cback: _ctx.SetPeriod
    }, null, 8, ["options", "value", "cback"])
  ]))
}